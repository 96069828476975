import constants from '~/constants/index.server'

type CommonMetaTags = Partial<{
  title: string
  description: string
  socialImage: string
  pathname: string
  indexable: boolean
}>

const defaultTitle =
  'Lemon Energia | Energia sustentável, digital e mais barata pro seu negócio'

const defaultDescription =
  'Com a ajuda da Lemon, você sempre - e é sempre mesmo - vai ter energia limpa e economia para o seu negócio. Tudo digital!'

function generateSocialImage(
  socialImage = '/social-media-thumb.png',
  baseUrl?: string,
) {
  let href: string | null = null

  if (socialImage.includes('https://')) {
    href = socialImage
  } else if (baseUrl) {
    href = new URL(socialImage, baseUrl).href
  }

  if (!href) {
    return []
  }

  return ['og:image', 'twitter:image'].map(el => ({
    property: el,
    content: href,
  }))
}

function generateCanonical(pathname?: string, baseUrl?: string) {
  if (!pathname || !baseUrl) {
    return []
  }

  return [{ rel: 'canonical', href: new URL(pathname, baseUrl).href }]
}

function generateRobots(indexable = true) {
  if (indexable) return []

  return [
    {
      name: 'robots',
      content: 'noindex',
    },
  ]
}

export const commonMetaTags = ({
  title = defaultTitle,
  description = defaultDescription,
  socialImage,
  pathname,
  indexable,
}: CommonMetaTags) => {
  const baseUrl =
    typeof window === 'undefined' ? constants?.baseUrl : window?.env?.baseUrl

  const socialImages = generateSocialImage(socialImage, baseUrl)
  const canonical = generateCanonical(pathname, baseUrl)
  const robots = generateRobots(indexable)

  return [
    {
      title,
    },
    {
      name: 'description',
      content: description,
    },

    // facebook
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'facebook-domain-verification',
      content: 'anc5bcvuwcmovh713vthw6nhcbjp5n',
    },

    // twitter
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    ...socialImages,
    ...canonical,
    ...robots,
  ]
}
